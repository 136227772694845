<template>
  <div>
    <!-- Start Header Area -->
    <Header class="digital-agency-header color-white">
      <img slot="logo" src="../assets/images/logo/logo-light.png" />
    </Header>
    <!-- End Header Area -->

    <!-- Start Slider Area -->
    <SliderOne />
    <!-- End Slider Area -->

    <!-- Start About Area  -->
    <div class="about-area rn-section-gap bg_color--1">
      <div class="about-wrapper">
        <About>
          <img
            slot="thum-img"
            class="w-100 w-50-sm"
            src="../assets/images/home/programmer.gif"
            alt="About Images"
          />

          <router-link to="/about" slot="about-extra">
            <a class="rn-button-style--2 btn_solid mt--30" href="/portfolio"
              ><span>Learn More</span></a
            >
          </router-link>
        </About>
      </div>
    </div>
    <!-- Start About Area  -->

    <!-- Start Service Area  -->
    <div class="rn-service-area rn-section-gap bg_color--1">
      <v-container>
        <v-row>
          <v-col lg="4" md="4" sm="12" cols="12">
            <div
              class="section-title text-left mt--30 mt_md--5 mt_mobile--5 mb_mobile--10"
            >
              <h2 class="heading-title">what we offer.</h2>
              <p>
                We offer a great variety of ways to help your business grow.
              </p>
              <div class="service-btn">
                <router-link to="/contact">
                  <a class="btn-transparent rn-btn-dark" href="/service"
                    ><span class="text">Request Custom Service</span></a
                  >
                </router-link>
              </div>
            </div>
          </v-col>
          <v-col lg="8" md="8" sm="12" cols="12" class="mt_md--50">
            <ServiceTwo />
          </v-col>
        </v-row>
      </v-container>
    </div>
    <!-- Start Service Area  -->

    <!-- Start Portfolio Area  -->
    <div class="rn-portfolio-area rn-section-gap bg_color--1">
      <div class="portfolio-sacousel-inner pb--30">
        <div class="container">
          <div class="row">
            <v-col lg="12">
              <div class="section-title text-center mb--20 mb_sm--0 mb_md--0">
                <h2 class="heading-title">recent projects.</h2>
                <p>
                  Here are some of our latest creations.
                </p>
              </div>
            </v-col>
          </div>
        </div>
        <Portfolio />
      </div>
    </div>
    <!-- Start Portfolio Area  -->

    <!-- Start About Area  -->
    <div class="about-area pb--140 bg_color--1">
      <div class="about-wrapper">
        <v-container>
          <v-row class="row--35">
            <v-col md="6" cols="12">
              <div>
                <img
                  class="w-100"
                  src="../assets/images/home/dev.png"
                  alt="About Images"
                />
              </div>
            </v-col>
            <v-col md="6" cols="12">
              <div class="about-inner inner mt--20 mt_sm--0">
                <div class="section-title">
                  <h2 class="heading-title">why us?</h2>
                  <p class="description">
                    Great question. Let us explain.
                  </p>
                </div>
                <div class="row mt--30">
                  <Tab />
                </div>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </div>
    </div>
    <!-- End About Area  -->

    <!-- Start Testimonial Area  -->
    <div class="rn-testimonial-area rn-section-gap bg_color--5">
      <v-container>
        <Testimonial />
      </v-container>
    </div>
    <!-- Start Testimonial Area  -->

    <!-- Start Call to action Area  -->
    <CallToAction />
    <!-- End Call to action Area  -->

    <!-- Start Footer Area  -->
    <Footer />
    <!-- End Footer Area  -->
  </div>
</template>
<script>
  import Header from "../components/header/Header";
  import SliderOne from "../components/slider/SliderOne";
  import About from "../components/about/About";
  import ServiceTwo from "../components/service/ServiceTwo";
  import Portfolio from "../components/portfolio/Portfolio";
  import Testimonial from "../components/testimonial/Testimonial";
  import Blog from "../components/blog/Blog";
  import CallToAction from "../components/callto-action/CallToAction.vue";
  import BrandTwo from "../components/brand/BrandTwo.vue";
  import Footer from "../components/footer/FooterTwo";
  import Tab from "../components/tabs/Tab";
  import Accordion from "../components/accordion/Accordion";
  export default {
    components: {
      Header,
      SliderOne,
      About,
      ServiceTwo,
      Portfolio,
      Testimonial,
      Blog,
      Tab,
      BrandTwo,
      CallToAction,
      Footer,
      Accordion,
    },
    data() {
      return {};
    },
    mounted() {
      let liveChatScript = document.createElement("script");
      liveChatScript.setAttribute(
        "src",
        "//js-na1.hs-scripts.com/22756266.js"
      );
      document.head.appendChild(liveChatScript);
    },
  };
</script>

<style lang="scss">
  .bg-white--service {
    .single-service.service__style--2.bg-color-gray a {
      background: #fff;
      text-align: center;
    }
  }
</style>
