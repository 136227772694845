import Vue from "vue";
import VueRouter from "vue-router";
import goTo from "vuetify/es5/services/goto";
import Missing from "../views/404.vue";
import Home from "../views/Home.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    component: Home,
    meta: {
      title: "studio one.",
    },
  },

  {
    path: "/about",
    name: "About",
    meta: {
      title: "About | studio one.",
    },
    component: () => import("../views/About.vue"),
  },
  {
    path: "/services",
    name: "Services",
    meta: {
      title: "Services | studio one.",
    },
    component: () => import("../views/Services.vue"),
  },
  {
    path: "/services/development",
    name: "Development",
    meta: {
      title: "Service | studio one.",
    },
    component: () => import("../views/Services.vue"),
  },
  {
    path: "/services/design",
    name: "Design",
    meta: {
      title: "Service | studio one.",
    },
    component: () => import("../views/Services.vue"),
  },
  {
    path: "/services/marketing",
    name: "Marketing",
    meta: {
      title: "Marketing | studio one.",
    },
    component: () => import("../views/Services.vue"),
  },
  {
    path: "/services/photovideo",
    name: "PhotoVideo",
    meta: {
      title: "Photo/Video | studio one.",
    },
    component: () => import("../views/Services.vue"),
  },
  {
    path: "/portfolio",
    name: "Portfolio",
    meta: {
      title: "Portfolio | studio one.",
    },
    component: () => import("../views/portfolio/Portfolio.vue"),
  },
  {
    path: "/contact",
    name: "Contact",
    meta: {
      title: "Contact | studio one.",
    },
    component: () => import("../views/Contact.vue"),
  },

  // portfolio pages
  {
    path: "/portfolio/paul",
    name: "Paul's Portfolio",
    meta: {
      title: "Portfolio | Paul's Portfolio | studio one.",
    },
    component: () => import("../views/portfolio/pages/Paul.vue"),
  },
  {
    path: "/portfolio/brothersfreight",
    name: "BrothersFreight",
    meta: {
      title: "Portfolio | Brothers Freight Management | studio one.",
    },
    component: () => import("../views/portfolio/pages/BrothersFreight.vue"),
  },
  {
    path: "/portfolio/mainstreetcreamery",
    name: "MainStreetCreamery",
    meta: {
      title: "Portfolio | Sarasota Main Street Creamery | studio one.",
    },
    component: () => import("../views/portfolio/pages/MainStreetCreamery.vue"),
  },
  {
    path: "/portfolio/bestfuturehomes",
    name: "Bestfuturehomes",
    meta: {
      title: "Portfolio | Best Future Homes | studio one.",
    },
    component: () => import("../views/portfolio/pages/Bestfuturehomes.vue"),
  },
  {
    path: "/portfolio/homeplus",
    name: "Roofing by HomePlus",
    meta: {
      title: "Portfolio | HomePlus | studio one.",
    },
    component: () => import("../views/portfolio/pages/Homeplus.vue"),
  },
  {
    path: "/portfolio/leonovflooring",
    name: "LeonovFlooring",
    meta: {
      title: "Portfolio | Leonov Flooring | studio one.",
    },
    component: () => import("../views/portfolio/pages/LeonovFlooring.vue"),
  },
  {
    path: "/portfolio/Siesta",
    name: "Siesta",
    meta: {
      title: "Portfolio | Siesta | studio one.",
    },
    component: () => import("../views/portfolio/pages/Siesta.vue"),
  },
  
  {
    path: "*",
    component: Missing,
    meta: {
      title: "404 | studio one.",
    },
  },
];

const router = new VueRouter({
  mode: "history",
  routes,
  scrollBehavior: (to, from, savedPosition) => {
    let scrollTo = 0;

    if (to.hash) {
      scrollTo = to.hash;
    } else if (savedPosition) {
      scrollTo = savedPosition.y;
    }

    return goTo(scrollTo);
  },
});

router.beforeEach((to, from, next) => {
  document.title = to.meta.title;
  next();
});

export default router;
