<template>
  <v-col lg="12">
    <div class="tab-default">
      <v-tabs v-model="tab" flat hide-slider>
        <v-tab v-for="item in items" :key="item.id" :ripple="false">
          {{ item.name }}
        </v-tab>
      </v-tabs>

      <v-tabs-items v-model="tab">
        <v-tab-item v-for="item in tabContent" :key="item.id">
          <div class="single-tab-content">
            <p>
              {{ item.desc }}
            </p>
            <div class="list0style-inner mt--30">
              <!-- <h4>Lorem ipsum dolor sit.</h4> -->
              <ul class="list-style--1">
                <li class="d-flex" v-for="(list, i) in item.checklist" :key="i">
                  <i v-html="iconSvg(list.icon)"></i>
                  {{ list.desc }}
                </li>
              </ul>
            </div>
          </div>
        </v-tab-item>
      </v-tabs-items>
    </div>
  </v-col>
</template>
<script>
  import feather from "feather-icons";
  export default {
    data() {
      return {
        tab: null,
        items: [
          {
            id: 1,
            name: "Technology",
          },
          {
            id: 2,
            name: "Our Mission",
          },
          {
            id: 3,
            name: "Our Values",
          },
        ],
        tabContent: [
          {
            id: 1,
            desc: `We pride ourselves in using cutting-edge technology. We stay up to date with the newest developments in the tech world, and try to take advantage of them.`,
            checklist: [
              {
                icon: "check",
                desc: `Modern stack for building web applications`,
              },
              {
                icon: "check",
                desc: `Industry-leading tools and equipment`,
              },
              {
                icon: "check",
                desc: `Powered by fast and reliable cloud solutions`,
              },
              {
                icon: "check",
                desc: `Industry professionals with real experience`,
              },
            ],
          },
          {
            id: 2,
            desc: `Nobody likes receiving low-quality work or being left with no response when dealing with a company. We're here to make it as easy for you as possible. We are committed to delivering high-quality work with the best possible service.`,
            checklist: [
              {
                icon: "check",
                desc: `High-quality product and services`,
              },              {
                icon: "check",
                desc: `All services tailored to your specific needs`,
              },
              {
                icon: "check",
                desc: `Clear and concise communication`,
              },
              {
                icon: "check",
                desc: `Hassle-free workflow`,
              },
            ],
          },
          {
            id: 3,
            desc: `We strive to deliver the best service we can with less headache for you. We want to help you achieve your goals, and we take it seriously - we do everything like we do it for ourselves.`,
            checklist: [
              {
                icon: "check",
                desc: `Honesty`,
              },
              {
                icon: "check",
                desc: `Transparency`,
              },
              {
                icon: "check",
                desc: `Commitment to quality`,
              },
              {
                icon: "check",
                desc: `Doing it right the first time`,
              },
            ],
          },
        ],
      };
    },
    methods: {
      iconSvg(icon) {
        return feather.icons[icon].toSvg();
      },
    },
  };
</script>
